<template>
  <v-app>
    <v-overlay 
      color="black"
      absolute
      opacity=".8"
      class="maintenance-overlay text-center"
    >
      <loading class="maintenance mb-4" />
      <span class="d-block text-overline mb-2">
        Em manutenção
      </span>
      <v-divider class="mx-auto my-2" />
      <span class="d-block text-caption grey--text mt-6">
        Desculpe o transtorno, <br>voltamos em breve.
      </span>
    </v-overlay>
  </v-app>
</template>

<style>

  .maintenance-overlay .maintenance {
    width: 48px;
    height: 48px;
    opacity: .8;
  }
  .maintenance-overlay hr {
    width: 80%;
  }
  
</style>

<script>
  import services from '@/services.js'

  export default {
    name: 'Maintenance',

    components: {
      Loading: () => import('@/components/IconLoading'),
    },

    data: () => ({
      helpContent: null,
      help: {
        version: null,
      }
    }),

    watch: {
      helpContent: {
        immediate: true,
        deep: true,
        handler (content) {
          if (!!content&&_.has(content.controller, 'maintenance')&&!content.controller.maintenance) {
            this.$router.replace({ path: '/' });
          }
        }
      }
    },

    methods: {
      ...services,

    },

    mounted () {
      this.loadHelpContent(false);
    }
  }
</script>
